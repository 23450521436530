import request from '@/utils/request'
const controller = '/UnitAddress'

const getUnitAddressList = unitId =>
  request({
    url: `${controller}/GetUnitAddressList`,
    method: 'get',
    params: { unitId }
  });
const getAreaTree = unitId =>
  request({
    url: `${controller}/GetAreaTree`,
    method: 'get',
    params: { unitId }
  });
const addUnitAddress = data =>
  request({
    url: `${controller}/Insert`,
    method: 'post',
    data
  });
export {
  getUnitAddressList,
  getAreaTree,
  addUnitAddress
}