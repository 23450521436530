<template>
  <el-form v-loading="!initialized" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" ref="elForm" :model="formData" :rules="rules" size="medium" label-width="80px" :disabled="isView">
    <!-- <el-row :gutter="15">
      <el-col :span="24">
        <el-form-item label="招聘企业" prop="EnterpriseId">
          <el-select :disabled="!isCanSelectEnterprise" v-model="formData.EnterpriseId" placeholder="请选择招聘企业" :style="{ width: '100%' }">
            <el-option v-for="(item, index) in CooperationOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row> -->
    <el-row :gutter="35">
      <el-col :span="24">
        <el-form-item label="职位分类" prop="JobCategoryBaseId">
          <el-cascader v-model="formData.JobCategoryBaseId" :options="JobCategoryOptions" :props="JobCategoryProps" :style="{ width: '100%' }" placeholder="请选择职位类型" @change="categoryChange" clearable />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="35">
      <el-col :span="12">
        <el-form-item label="招聘性质" prop="RecruitNature">
          <el-select v-model="formData.RecruitNature" placeholder="请选择招聘性质" :style="{ width: '100%' }">
            <el-option v-for="(item, index) in RecruitNatureOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="招聘类型" prop="RecruitType">
          <el-select v-model="formData.RecruitType" placeholder="请选择招聘类型" :style="{ width: '100%' }">
            <el-option v-for="(item, index) in RecruitTypeOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="35">
      <el-col :span="12">
        <el-form-item label="职位名称" prop="Name">
          <el-autocomplete v-model="formData.Name" clearable placeholder="请输入名称" :trigger-on-focus="false" :fetch-suggestions="searchJob" @select="handleSelect" :style="{ width: '100%' }" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="招聘人数" prop="RecruitCount">
          <el-input v-model.number="formData.RecruitCount" placeholder="请输入招聘人数" clearable :style="{ width: '100%' }" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="35">
      <el-col :span="12">
        <el-form-item label="工作经验" prop="Experience">
          <el-select v-model="formData.Experience" placeholder="请选择工作经验" :style="{ width: '100%' }">
            <el-option v-for="(item, index) in ExperienceOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="学历要求" prop="Education">
          <el-select v-model="formData.Education" placeholder="请选择学历要求" :style="{ width: '100%' }">
            <el-option v-for="(item, index) in EducationOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="35">
      <el-col :span="12">
        <div class="flex">
          <el-form-item label="薪资范围" prop="SalaryMin">
            <div class="input-wrap">
              <el-input-number v-model.number="formData.SalaryMin" placeholder="" controls-position="right" :min="1" :max="599">
              </el-input-number>
              <span class="input-unit">K</span>
            </div>
          </el-form-item>
          <el-form-item label="至" class="to" label-width="38px" prop="SalaryMax">
            <div class="input-wrap">
              <el-input-number v-model.number="formData.SalaryMax" placeholder="" controls-position="right" :min="2" :max="600">
              </el-input-number>
              <span class="input-unit">K</span>
            </div>
          </el-form-item>
        </div>
      </el-col>
      <!-- <el-col :span="12">
        <el-form-item label="能否实习" prop="IsInternshipJob">
          <el-select v-model="formData.IsInternshipJob" placeholder="请选择能否申请实习" :style="{ width: '100%' }">
            <el-option v-for="(item, index) in InternshipJobOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-col> -->
    </el-row>
    <!-- <el-row :gutter="35">
      <el-col :span="24">
        <el-form-item label="关联专业" prop="RecruitJobMajorList">
          <el-cascader v-model="formData.RecruitJobMajorList" :options="MajorIdOptions" :props="MajorIdProps" :style="{ width: '100%' }" placeholder="请选择关联专业" @change="majorChange" clearable />
        </el-form-item>
      </el-col>
    </el-row> -->
    <el-row :gutter="35">
      <el-col :span="24">
        <el-form-item label="关联能力" prop="RecruitJobAbilityList">
          <el-cascader v-model="formData.RecruitJobAbilityList" :options="AbilityIdOptions" :show-all-levels="true" :props="AbilityIdProps" :style="{ width: '100%' }" placeholder="请选择关联能力" @change="abilityChange" clearable />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="35">
      <el-col :span="24">
        <el-form-item label="岗位描述" prop="JobDescription">
          <el-input v-model="formData.JobDescription" type="textarea" placeholder="请输入岗位相关描述，如职责，要求等" :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="24">
        <el-form-item label="任职要求" prop="JobRequirement">
          <el-input v-model="formData.JobRequirement" type="textarea" placeholder="请输入任职要求" :autosize="{ minRows: 4, maxRows: 4 }" :style="{ width: '100%' }"></el-input>
        </el-form-item>
      </el-col> -->
    </el-row>
    <el-row :gutter="35">
      <el-col :span="24">
        <el-form-item label="岗位福利" prop="RecruitJobTagList">
          <el-select v-model="formData.RecruitJobTagList" value-key="Name" multiple filterable allow-create default-first-option placeholder="请选择标签" :style="{ width: '100%' }">
            <el-option v-for="(item,index) in RecruitJobTagOptions" :key="index" :label="item.Name" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="工作地点" prop="UnitAddressId">
          <el-select v-model="formData.UnitAddressId" default-first-option placeholder="请选择工作地点" :style="{ width: '100%' }" @change="changeAddress">
            <el-option v-for="item in UnitAddressOptions" :key="item.value" :label="item.label" :a="item.value" :value="areaDefault(item.value)">
              <div v-if="item.value" class="address-option" style="display:flex;">
                <span>{{item.label}}</span>
                <!-- //todo:暂时先不考虑删除，会导致其他引用的问题，后续考虑此处的删除仅仅改变状态，就不会成为候选了 -->
                <!-- <el-button type="text" size="small" @click.stop="deleteAddress(item.value)">删除</el-button> -->
              </div>
              <span v-else>{{item.label}}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="所属部门" prop="Department">
          <el-input v-model="formData.Department" placeholder="请输入所属部门" clearable />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="!isView">
      <el-col :span="24">
        <el-form-item size="large">
          <el-button type="primary" @click="submitForm" :loading="submitting">提交</el-button>
          <el-button @click="handleCancel">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>

    <el-dialog title="添加新的工作地址" :visible.sync="addressVisible">
      <el-form ref="addressform" :model="addressform" :rules="addressRule">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item :style="{ width: '100%' }" prop="AreaCodeArray">
              <el-cascader v-model="addressform.AreaCodeArray" :options="AreaOptions" :props="AreaProps" :style="{ width: '100%' }" placeholder="请选择城市" @change="changeArea" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="Address">
              <el-input v-model="addressform.Address" autocomplete="off" placeholder="请输入详细地址" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addressVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAddress">添 加</el-button>
      </div>
    </el-dialog>

  </el-form>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { getInfo, createJob, editJob, getJobList, getJobTagList } from "@/api/job";
import { RecruitNatureEnum, RecruitTypeEnum, StaffTypeEnum } from "@/utils/constant";
import { getCustomerList } from '@/api/cooperation';
import { getCooperationEnterpriseList } from '@/api/schoolenterprise'
import { getUnitAddressList, addUnitAddress } from '@/api/unitaddress';
import { arrayToTree } from '@/utils/util';
export default {
  components: {},
  props: {
    id: {
      type: String,
      default: ""
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initialized: false,
      value: "",
      loading: false,
      formData: {
        RecruitType: undefined,
        RecruitNature: undefined,
        JobCategoryBaseId: "",
        MajorId: [],
        Name: "",
        RecruitCount: undefined,
        Experience: undefined,
        Education: undefined,
        SalaryMin: undefined,
        SalaryMax: undefined,
        JobDescription: undefined,
        JobRequirement: undefined,
        RecruitJobTagList: [],
        RecruitJobMajorList: [],
        RecruitJobAbilityList: [],
        Benefits: undefined,
        WorkPlace: undefined,
        Department: undefined,
        EnterpriseName: undefined,
        UnitId: undefined,
        EnterpriseId: undefined,
        RecruitJobPushSchoolList: [],
        UnitAddressId: undefined,
        IsInternshipJob: false
      },
      addressform: {
        UnitId: undefined,
        UnitType: undefined,
        ProvinceCode: undefined,
        CityCode: undefined,
        RegionCode: undefined,
        Address: undefined,
        AreaCodeArray: []
      },
      rules: {
        UnitId: [
          {
            required: true,
            message: "请选择合作企业",
            trigger: "change"
          }
        ],
        RecruitType: [
          {
            required: true,
            message: "请选择招聘类型",
            trigger: "change"
          }
        ],
        RecruitNature: [
          {
            required: true,
            message: "请选择招聘性质",
            trigger: "change"
          }
        ],
        JobCategoryBaseId: [
          {
            required: true,
            // type: "array",
            message: "请选择职位类型",
            trigger: "change"
          }
        ],
        // RecruitJobMajorList: [
        //   {
        //     required: true,
        //     // type: "array",
        //     message: "请选择关联专业",
        //     trigger: "change"
        //   }
        // ],
        Name: [
          {
            required: true,
            message: "请输入职位名称",
            trigger: "blur"
          }
        ],
        RecruitCount: [
          {
            required: true,
            type: "number",
            message: "请输入招聘人数",
            trigger: "blur"
          }
        ],
        Experience: [
          {
            required: true,
            message: "请选择工作经验",
            trigger: "change"
          }
        ],
        Education: [
          {
            required: true,
            message: "请选择学历要求",
            trigger: "change"
          }
        ],
        SalaryMin: [
          {
            required: true,
            type: "number",
            // message: "请输入最低薪资", 
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入最低薪资'));
              } else if (value > this.formData.SalaryMax) {
                callback(new Error('薪资有误!'));
              } else {
                callback();
              }
            }
          }
        ],
        SalaryMax: [
          {
            required: true,
            type: "number",
            // message: "请输入最高薪资",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入最高薪资'));
              } else if (value < this.formData.SalaryMin) {
                callback(new Error('薪资有误!'));
              } else {
                callback();
              }
            }
          }
        ],
        // JobDescription: [
        //   {
        //     required: true,
        //     message: "请输入岗位描述",
        //     trigger: "blur"
        //   }
        // ],
        // JobRequirement: [
        //   {
        //     required: true,
        //     message: "请输入任职要求",
        //     trigger: "blur"
        //   }
        // ],
        // RecruitJobTagList: [
        //   {
        //     required: true,
        //     message: "请输入岗位标签",
        //     trigger: "change"
        //   }
        // ],
        Benefits: [],
        // WorkPlace: [
        //   {
        //     required: true,
        //     message: "请选择工作地点",
        //     trigger: "change"
        //   }
        // ],
        // UnitAddressId: [
        //   {
        //     required: true,
        //     message: "请选择工作地点",
        //     trigger: "blur"
        //   }
        // ],
        // Department: [
        //   {
        //     required: true,
        //     message: "请输入所属部门",
        //     trigger: "blur"
        //   }
        // ],
        EnterpriseId: [
          {
            required: true,
            message: "请选择招聘企业",
            trigger: "change"
          }
        ],
        IsPush: [
          {
            required: true,
            message: "请选择是否推送合作院校",
            trigger: "change"
          }
        ],
        IsInternshipJob: [
          {
            required: true,
            message: "请选择是否接受实习生",
            trigger: "change"
          }
        ]
      },
      addressRule: {
        AreaCodeArray: [
          {
            required: true,
            message: "请选择工作城市",
            trigger: "blur"
          }
        ],
        Address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur"
          }
        ]
      },
      CooperationEnterpriseOptions: [],
      CooperationOptions: [],
      JobCategoryOptions: [],
      MajorIdOptions: [],
      AbilityIdOptions: [],
      RecruitJobTagOptions: [],
      JobOptions: [],
      ExperienceOptions: [],
      EducationOptions: [],
      JobCategoryProps: {
        multiple: false,
        label: "Name",
        value: "Id",
        children: "Children"
      },
      MajorIdProps: {
        multiple: false,
        label: "Name",
        value: "Id",
        children: "Children",
        multiple: true,
        checkStrictly: true,
        emitPath: false,
        expandTrigger: 'hover'
      },
      AbilityIdProps:{
        multiple: true,
        label: "Name",
        value: "Id",
        children: "Children",
        multiple: true,
        checkStrictly: false,
        emitPath: false,
        expandTrigger: 'hover'
      },
      AreaProps: {
        multiple: false,
        label: "Name",
        value: "Code",
        children: "Children"
      },
      RecruitJobTagIdArr: [],
      UnitAddressOptions: [{
        label: '+ 添加新地址',
        value: undefined
      }],
      AreaOptions: [],
      submitting: false,
      addressVisible: false,
      InternshipJobOptions: [
        {
          label: '是',
          value: true
        },
        {
          label: '否',
          value: false
        }
      ],
      RecruitTypeOptions: [
        {
          label: '全职',
          value: RecruitTypeEnum.FullTime
        },
        {
          label: '兼职',
          value: RecruitTypeEnum.PartTime
        }
      ],
      RecruitNatureOptions: [
        {
          label: '社招',
          value: RecruitNatureEnum.Social
        },
        {
          label: '校招',
          value: RecruitNatureEnum.School
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    ...mapState('common', ['AreaTree', 'Education', 'Experience', 'JobCategory', 'JobCategoryTree', 'Major', 'MajorTree','Ability']),
    routeBase() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return "enterprise";
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return "school";
      }
      //todo:后续需要修改精确匹配
      return "enterprise";
    },
    isCanSelectEnterprise() {
      return !this.$route.query.e && !this.isView && !this.isEdit;
    },
    areaDefault() {
      return value => value ? value : '';
    }
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      if (this.isEdit || this.isView) {
        const { Result: RecruitJobInfo } = await getInfo(this.id);
        this.RecruitJobTagIdArr = RecruitJobInfo.RecruitJobTagList;
        this.formData = Object.assign({}, this.formData, RecruitJobInfo,
          {
            RecruitJobMajorList: RecruitJobInfo.RecruitJobMajorList.map(item => item.MajorId),
            RecruitJobAbilityList: RecruitJobInfo.RecruitJobAbilityList.map(item => item.AbilityContentId)
          });
      }

      this.ExperienceOptions = this.Experience.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));

      this.EducationOptions = this.Education.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));

      this.JobCategoryOptions = this.deleteChildren(this.JobCategoryTree);
      this.MajorIdOptions = this.deleteChildren(this.MajorTree);
      this.AbilityIdOptions = arrayToTree(this.Ability);
      const { Result: JobTagList = [] } = await getJobTagList();
      const { RecruitJobTagList = [] } = this.formData;
      this.RecruitJobTagOptions = JobTagList.map(({ Text, Value }) => {
        const selectedItem = RecruitJobTagList.find(({ Name }) => Name === Text);
        if (selectedItem === undefined) {
          return {
            DictionaryDetailId: Value,
            Name: Text
          };
        } else {
          return {
            DictionaryDetailId: Value,
            Name: Text,
            Id: selectedItem.Id || ''
          };
        }
      });

      if (this.$route.matched.some(({ path }) => path.includes("enterprise"))) {
        const { Employer: { Id, EnterpriseName } = {} } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.Enterprise) || {};
        this.formData.UnitType = StaffTypeEnum.Enterprise;
        this.formData.UnitId = Id;
        if (!this.isView && !this.isEdit) {
          this.formData.EnterpriseId = Id;
        }
        this.CooperationOptions = [{
          label: EnterpriseName,
          value: Id
        }];

        const { Result: CustomerList = [] } = await getCustomerList(Id);
        this.CooperationOptions = this.CooperationOptions.concat(CustomerList.map(item => ({
          label: item.Text,
          value: item.Value
        })));

      } else if (this.$route.matched.some(x => x.path.includes("school"))) {
        const { Employer: { Id } = {} } = this.employee.find(({ StaffType }) => StaffType === StaffTypeEnum.School) || {};
        this.formData.UnitType = StaffTypeEnum.School;
        this.formData.UnitId = Id;

        const { Result: CooperationEnterpriseList = [] } = await getCooperationEnterpriseList(Id);
        this.CooperationOptions = CooperationEnterpriseList.map(item => ({
          label: item.Text,
          value: item.Value
        }));
      }

      const { Result: UnitAddressList = [] } = await getUnitAddressList(this.formData.UnitId);
      this.UnitAddressOptions = UnitAddressList.map(item => ({
        label: item.Text,
        value: item.Value
      })).concat(this.UnitAddressOptions);

      if (this.$route.query.e) {
        this.formData.EnterpriseId = this.$route.query.e;
      }

      this.initialized = true;
    },
    jobChange(e) {
      if (e == "" || e.value == undefined) {
        this.formData.JobBaseId = "";
      } else {
        this.formData.JobBaseId = e.value;
      }
    },
    categoryChange(e) {
      this.formData.JobCategoryBaseId = e.length > 0 ? e[e.length - 1] : "";
    },
    majorChange(e) {
    },
    // async searchJob(query) {
    //   if (query !== '') {
    //     this.loading = true;
    //     const { Result } = await getJobList(
    //       query,
    //       this.formData.JobCategoryBaseId
    //     );
    //     this.loading = false;
    //     this.JobOptions = Result.map(({ Id, Name }) => ({
    //       label: Name,
    //       value: Id,
    //     }));
    //   }
    // },
    abilityChange(e) {
    },
    async searchJob(query, cb) {
      this.formData.JobBaseId = "";
      if (query) {
        const { Result } = await getJobList(query, this.formData.JobCategoryBaseId);
        cb(Result.map(({ Id, Name }) => ({ Id, value: Name })));
      } else {
        cb([]);
      }
    },
    handleSelect({ Id }) {
      this.formData.JobBaseId = Id;
    },
    changeAddress(val) {
      if (val) {
        this.formData.UnitAddressId = val;
      } else {
        this.formData.UnitAddressId = undefined;
        this.addressVisible = true;
        this.resetAddressForm();
        if (!this.AreaOptions.length) {
          this.AreaOptions = this.deleteChildren(this.AreaTree);
        }
      }
    },
    deleteAddress(unitAddressId) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning',
      }).then(_ => {
        if (unitAddressId === this.formData.UnitAddressId) {
          this.formData.UnitAddressId = undefined;
        }
        this.UnitAddressOptions = this.UnitAddressOptions.filter(item => item.value != unitAddressId);
      }).catch(_ => { });
    },
    changeArea(areaCodeArray) {
      this.addressform.ProvinceCode = areaCodeArray[0];
      this.addressform.CityCode = areaCodeArray[1];
      this.addressform.RegionCode = areaCodeArray[2];
    },
    deleteChildren(arr) {
      const childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].Children && childs[i].Children.length) {
          this.deleteChildren(childs[i].Children);
        } else {
          delete childs[i].Children;
        }
      }
      return arr;
    },
    async submitForm() {
      // if (!this.formData.SalaryMin || !this.formData.SalaryMax) {
      //   this.$message.error("请输入薪资范围！")
      //   return
      // }
      this.$refs["elForm"].validate(async isValid => {
        if (!isValid) {
          return;
        }
        this.submitting = true;
        const { Name, RecruitJobTagList = [], RecruitJobMajorList = [],RecruitJobAbilityList=[] } = this.formData;
        const data = Object.assign({}, this.formData,
          {
            Name: Name.value == undefined ? Name : Name.label,
            JobBaseId: Name.value == undefined ? '' : Name.value,
            RecruitJobTagList: RecruitJobTagList.map(item => {
              if (item.Name === undefined) {
                return { Name: item };
              } else {
                return { ...item };
              }
            }),
            RecruitJobMajorList: RecruitJobMajorList.map(item => ({
              MajorId: item
            })),
            RecruitJobAbilityList: RecruitJobAbilityList.map(item => ({
              AbilityContentId: item
            }))
          });
        try {
          if (this.isEdit) {
            await editJob(data);
          } else {
            await createJob(data);
          }
          this.handleCancel();
        } catch (error) {
          this.submitting = false;
        }
      });
    },
    submitAddress() {
      this.addressform.UnitId = this.formData.UnitId;
      this.addressform.UnitType = this.formData.UnitType;
      this.$refs['addressform'].validate(isValid => {
        if (!isValid) {
          return;
        }
        addUnitAddress(this.addressform).then(({ Result: { Id, FullAddress } }) => {
          this.UnitAddressOptions.unshift({
            label: FullAddress,
            value: Id
          });
          this.addressVisible = false;
        });
      });
    },
    resetAddressForm() {
      this.$nextTick(() => {
        this.$refs['addressform'].clearValidate();
      });
      this.addressform.ProvinceCode = undefined;
      this.addressform.ProvinceCode = undefined;
      this.addressform.CityCode = undefined;
      this.addressform.RegionCode = undefined;
      this.addressform.Address = undefined;
      this.addressform.AreaCodeArray = [];
    },
    handleCancel() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.form {
  background: #fff;
  padding: 48px;
  margin-bottom: 20px;
  border-radius: 8px;
  .to {
    ::v-deep label {
      &::before {
        display: none;
      }
    }
  }
}
.address-option {
  display: flex;
  justify-content: space-between;
}
.input-wrap {
  display: flex;
  width: 151px;
  .input-unit {
    left: -52px;
    position: relative;
    color: #909399;
  }
}

.slot-input ::v-deep {
  .el-input-group__append {
    background-color: #fff;
    padding: 8px;
    border-left: none;
  }
}
</style>
